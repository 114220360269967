<template>
  <div class="wrapper">
    <div class="title">关于我们</div>
    <img class="image" src="@/assets/about.jpg" alt="about"/>
    <div class="title3">
      智安康是一个为养老用户服务的一站式健康管理养老平台，通过智能可穿戴设备监测用户的身体健康指数、监测用户身体形态异常，如跌倒、SOS报警。根据用户身体健康数据结合AI算法为用户提供患病风险评估、设备报警时的医疗救助、身体指数异常的就医协助及保险保障服务，为用户的健康保驾护航，为老人和子女提供亲情关爱的纽带。
    </div>
  </div>
</template>
<style lang="scss" scoped>
.wrapper {
  width: calc(100% - 600px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  .image {
    width: 100%;
    height: 400px;
    margin-top: 40px;
  }

  .title {
    margin-top: 40px;
  }

  .title3 {
    margin-top: 40px;
    line-height: 30px;
    text-align: start;
  }
}
</style>
